import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";

import PropTypes from "prop-types";

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};

class WidgetGameByDate extends Component {
  render() {
    const {
      data: { data },
      loading,
      selectedEventnames,
    } = this.props;

    return (
      <>
        {loading === true ? (
          <Spinner color="primary" size="md" />
        ) : (
          <Table responsive size="sm">
            <thead>
              {selectedEventnames.length > 0 ? (
                <tr>
                  <th colSpan={11}>STATS</th>
                  <th colSpan={selectedEventnames.length}>EVENT NAMES</th>
                </tr>
              ) : null}
              <tr>
                <th>Date</th>
                <th>Installs</th>
                <th>Average Total Play Time (m)</th>
                <th>Average Number of Times Played</th>
                <th>Average Number of Sessions</th>
                <th>Average Game Level</th>
                <th>Retention D1 (%)</th>
                <th>Retention D3 (%)</th>
                <th>Retention D7 (%)</th>
                <th>Retention D14 (%)</th>
                <th>Retention D30 (%)</th>
                <th>Retention D60 (%)</th>
                <th>Retention D90 (%)</th>
                <th>Purchases</th>
                <th>% of People who purchased</th>
                {selectedEventnames.map((name) => (
                  <th key={`events-${name}`}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, i) => {
                const {
                  r_retention_ratio_day_1,
                  r_retention_ratio_day_3,
                  r_retention_ratio_day_7,
                  r_retention_ratio_day_14,
                  r_retention_ratio_day_30,
                  r_retention_ratio_day_60,
                  r_retention_ratio_day_90,
                  nop,
                  atpt,
                  anotp,
                  anos,
                  agl,
                  p,
                  pop,
                } = row;
                return (
                  <tr key={`${row._id}-${i}`}>
                    <td>{row._id}</td>
                    <td>{nop}</td>
                    <td>{atpt}</td>
                    <td>{anotp}</td>
                    <td>{anos}</td>
                    <td>{agl}</td>
                    <td>{r_retention_ratio_day_1}</td>
                    <td>{r_retention_ratio_day_3}</td>
                    <td>{r_retention_ratio_day_7}</td>
                    <td>{r_retention_ratio_day_14}</td>
                    <td>{r_retention_ratio_day_30}</td>
                    <td>{r_retention_ratio_day_60}</td>
                    <td>{r_retention_ratio_day_90}</td>
                    <td>{p}</td>
                    <td>{pop}</td>
                    {selectedEventnames.map((e) => (
                      <td key={`e_${e}`}>{row[`e_${e}`]}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

WidgetGameByDate.propTypes = propTypes;

export default WidgetGameByDate;
